import React, { useState } from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

const IndexPage = props => {
  const slides = [
    {
      html: (
        <Img
          fluid={props.data.huizarMostrador.childImageSharp.fluid}
          alt="huizar mostrador"
        />
      ),
      caption: "Mas de 50 años atendiendo a Mexicali, orgullosamente",
    },
    {
      html: (
        <Img
          fluid={props.data.servicioDomicilio.childImageSharp.fluid}
          alt="servicio a domicilio"
        />
      ),
      caption: "Contamos con servicio a domicilio - area limitada",
    },
    {
      html: (
        <Img
          fluid={props.data.planchadoSeco.childImageSharp.fluid}
          alt="planchado seco"
        />
      ),
      caption: "Te atendemos con gusto de lunes a sabado de 7:30 AM A 8:00 PM",
    },
  ]

  const [activeCaption, setActiveCaption] = useState(slides[0].caption)

  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 9000,
    initialSlide: 0,
    dots: true,
    dotsClass: "slick-overlay",
    // speed: 3000,
    appendDots: dots => (
      <div className="myClass">
        <div className="slick-caption">{activeCaption}</div>
        <ul className="_slick-dots">{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <button class="button" aria-label={`fotografia ${i}`}>
        <div className="bg-img" />
      </button>
    ),
    beforeChange: (oldIndex, newIndex) => {
      updateSlide(newIndex)
    },
  }

  const updateSlide = newIndex => {
    setActiveCaption(slides[newIndex].caption)
  }

  return (
    <Layout>
      <SEO title="" />
      <div className="mc-wrapper-2">
        <Slider {...settings}>
          {slides.map((slide, index) => slide.html)}
        </Slider>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    huizarMostrador: file(
      relativePath: { eq: "limpiaduria-huizar-mostrador.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    planchadoSeco: file(relativePath: { eq: "planchado-lavado-en-seco.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    servicioDomicilio: file(relativePath: { eq: "servicio-a-domicilio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
